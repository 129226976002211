import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  maxLength,
  ReferenceInput,
  SelectInput,
  Toolbar,
  SaveButton,
  useGetList,
  useRecordContext,
  DeleteWithConfirmButton,
} from 'react-admin';
import BackButton from '../Components/BackButton';
import TveTitle from '../Components/TveTitle';
import { CircularProgress } from '@mui/material';

const CustomToolbar = () => {
  const record = useRecordContext();
  const { data, isLoading, error } = useGetList('tveclasses', {
    pagination: { page: 1, perPage: 1000 },
  });
  const deleteConfirmationMessageTitle = `Delete training location: ` + record.displayName;
  if (isLoading) {
    return <CircularProgress />;
  }
  if (error) {
    return <p>{error.message}</p>;
  }
  return (
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <SaveButton />
      {data !== undefined && (
        <DeleteWithConfirmButton
          disabled={data.filter((i) => i.tveTrainingLocation?.id === record.id).length > 0}
          confirmTitle={deleteConfirmationMessageTitle}
          confirmContent="Are you sure you want to delete this training location?"
          variant="contained"
          sx={{
            color: 'white',
            backgroundColor: 'red',
            padding: '6px 11px',
            '&:hover': {
              backgroundColor: '#0066CC',
            },
          }}
        />
      )}
    </Toolbar>
  );
};

const TveTrainingLocationEdit = () => {
  return (
    <>
      <BackButton label="Return to Training Location List" to="/traininglocations" />
      <Edit title={<TveTitle pageName="Edit Training Location" />}>
        <SimpleForm toolbar={<CustomToolbar />}>
          <TextInput
            label="Training Location"
            source="location"
            validate={[required(), maxLength(255)]}
            sx={{ width: 500 }}
          />
          <TextInput
            label="Location Code"
            source="locationCode"
            validate={[required(), maxLength(3)]}
            sx={{ width: 500 }}
          />
          <TextInput label="State" source="state" validate={maxLength(255)} sx={{ width: 500 }} />
          <TextInput
            label="Country"
            source="country"
            validate={maxLength(255)}
            sx={{ width: 500 }}
          />
          <TextInput
            label="Display Name"
            source="displayName"
            validate={[required(), maxLength(255)]}
            sx={{ width: 500 }}
          />
          <ReferenceInput
            source="tveTimeZone.id"
            reference="timezones"
            sort={{ field: 'sort', order: 'ASC' }}
            sx={{ width: 500 }}
          >
            <SelectInput label="Default Timezone" optionText="official" validate={[required()]} />
          </ReferenceInput>
          <TextInput label="Sort Order" source="sort" validate={maxLength(255)} />
        </SimpleForm>
      </Edit>
    </>
  );
};
export default TveTrainingLocationEdit;
