import axios from 'axios'
import StoredUser from '../System/StoredUser'
// import { STIG_MESSAGE_ACCEPTED } from "../Pages/StigMessage"
import { API_URL, AUTH_URL } from './ReactAppConfig'
import { CANES_TVE_ENV } from '../Config/ReactAppConfig';

const GUAC_AUTH = "GUAC_AUTH"

const ROLES = [
    { roleId: 1, name: "STUDENT", description: "" },
    { roleId: 2, name: "INSTRUCTOR", description: "" },
    { roleId: 3, name: "ADMIN", description: "" },
    { roleId: 4, name: "DEVELOPER", description: "" },
]

const LOGIN_ROLES = [
    { roleId: 1, name: "STUDENT", description: "" },
    { roleId: 2, name: "INSTRUCTOR", description: "" }
]

// LOGIN_ORGS is hardcoded here, perhaps because it's used before the user is registered.
// But now the values are different in 'TEST' compared to the other environments.
// Keeping the hardcoding in place for now, but this should probably be refactored.

let LOGIN_ORGS;

if (CANES_TVE_ENV === 'TEST') {
  LOGIN_ORGS = [
    { orgId: 6, name: "CIWT", description: "" },
    { orgId: 2, name: "Government", description: "" },
    { orgId: 3, name: "SAIC", description: "" },
    { orgId: 1, name: "Deloitte", description: "" },
    { orgId: 4, name: "Techsoft", description: "" },
    { orgId: 5, name: "Otherz", description: "" },
    // Booz and G2IT apparently only should be in TEST
    { orgId: 7, name: "Booz | Allen | Hamilton", description: "" },
    { orgId: 8, name: "G2IT LLC", description: "" }
  ];
} else {
  LOGIN_ORGS = [
    { orgId: 6, name: "CIWT", description: "" },
    { orgId: 2, name: "Government", description: "" },
    { orgId: 3, name: "SAIC", description: "" },
    { orgId: 1, name: "Deloitte", description: "" },
    { orgId: 4, name: "Techsoft", description: "" },
    { orgId: 5, name: "Others", description: "" },
  ];
}

const AUTH_ERROR_STATUSES = [ 401, 408]

const loginRedirect = () => window.location.href = `${AUTH_URL}/token-with-redirect`

const registerRedirect = (roleId, orgId, email, phoneNumber) => window.location.href = `${AUTH_URL}/access_request-with-redirect?role_id=${roleId}&org_id=${orgId}&email=${email}&phone_number=${phoneNumber}`

const getErrorStatusCode = error => {
    if (!error) {
        // handle undefined error case; without this, a TypeError is thrown
        return "error is undefined: " + error;
    }

    if ("status" in error) 
        return parseInt(error.status)

    if ("message" in error)
        return parseInt(error.message.replace("Request failed with status code ", ""))

    return error
}

const storedUser = new StoredUser()

const authProvider = {

    login: ({ token }) => axios({
            method: 'get',
            headers: {
                Authorization: `Bearer ${token}`
            },
            url: `${API_URL}/me`
        }).then( 
            ({data}) => {
                storedUser.store({ 
                    ...data, 
                    token,
                    permissions: data.role.name
                })
                document.cookie = `auth=${token}; SameSite=None; Secure`
            }
        ),

    logout: () => {
        storedUser.remove()
        localStorage.removeItem(GUAC_AUTH)
        // sessionStorage.removeItem(STIG_MESSAGE_ACCEPTED)
        document.cookie = "auth=; SameSite=None; Secure"

        return Promise.resolve()
    },

    checkAuth: () => storedUser.exists() ? Promise.resolve(storedUser.getPermissions()) : Promise.reject(),
    
    checkError:  (error) => AUTH_ERROR_STATUSES.includes(getErrorStatusCode(error)) ? Promise.reject() : Promise.resolve(),

    getIdentity: () => {
        if (!storedUser.exists()) {
            return Promise.reject()
        } else {
            const { id, username } = storedUser.load()
            return Promise.resolve({
                id,
                fullName: username,
                // avatar: //todo this could be cool to use.
            })
        }
    },

    getPermissions: () => storedUser.exists() ? Promise.resolve(storedUser.getPermissions()) : Promise.reject()

};

export default authProvider;

export {
    loginRedirect,
    registerRedirect,
    LOGIN_ROLES,
    LOGIN_ORGS,
    ROLES
}
