import React, { useState } from 'react';
import {
  Button,
  Confirm,
  useUpdateMany,
  useListContext,
  useRefresh,
  useNotify,
  useUnselectAll,
} from 'react-admin';

const TveButton = ({
  label,
  color,
  icon,
  action,
  fromTveStatuses,
  fromTrainingStatuses,
  fromCanesVersions,
  isCanesVersionPerformanceTestSettingEnabled,
}) => {
  const { data, selectedIds } = useListContext();
  const [open, setOpen] = useState(false);

  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('tveclassappusers');
  const handleClick = () => {
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const [updateMany, { isLoading }] = useUpdateMany();
  const handleConfirm = () => {
    setOpen(false);
    updateMany(
      'tveclassappusers',
      { ids: affectedUsers.map((it) => it.id), data: { tve: { action } } },
      {
        onSuccess: () => {
          refresh();
          unselectAll();
        },
        onError: (error) => notify('tve button error occured', { type: 'warning' }),
      }
    );
  };

  const isIntermediateTveStatus = (studentEntry) => studentEntry.tveStatus.name.endsWith('...');

  // for the ones already there it's matching the values in the button (e.g., "Running") against the status
  const affectedUsers = data
    .filter((it) => selectedIds.includes(it.id))
    .filter(
      (studentEntry) =>
        !isIntermediateTveStatus(studentEntry) &&
        (fromTveStatuses === 'ALL' || fromTveStatuses.includes(studentEntry.tveStatus.name)) &&
        // (fromCanesVersions === 'ALL' || fromCanesVersions.includes(studentEntry.canesVersion.name)) &&
        // (fromCanesVersions === 'ALL' || fromCanesVersions.some(status => studentEntry.canesVersion.name.includes(status)))
        // (fromCanesVersions === 'ALL' || fromCanesVersions.some(version => studentEntry.canesVersion.name.includes(version))) &&
        (fromCanesVersions === 'ALL' ||
          fromCanesVersions.includes(studentEntry.canesVersion.hasPerformanceTest)) &&
        (fromTrainingStatuses === 'ALL' ||
          fromTrainingStatuses.includes(studentEntry.appUserTrainingStatus.name))
    );

  return affectedUsers.length === 0 ? null : (
    <>
      <Button
        onClick={handleClick}
        label={`${label} (${affectedUsers.length})`}
        variant="contained"
        color={color}
      >
        {icon}
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={`${label} for ${affectedUsers.length} students`}
        content={`Are you sure you want to ${label} for  ${affectedUsers
          .map((student) => student.tveClassAppUserId)
          .join(', ')}?`}
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default TveButton;
