import React from 'react';
import {
  InfiniteList,
  InfinitePagination,
  useListContext,
  TextField,
  DatagridConfigurable,
  CreateButton,
  TopToolbar,
  SelectColumnsButton,
  FunctionField,
} from 'react-admin';
import TveTitle from '../Components/TveTitle';
import Clock from '../Components/Clock';
import { Box, Card, Typography } from '@mui/material';

const CustomPagination = () => {
  const { total } = useListContext();
  return (
      <>
          <InfinitePagination />
          {total > 0 && (
              <Box position="sticky" bottom={0} textAlign="center">
                  <Card
                      elevation={2}
                      sx={{ px: 2, py: 1, mb: 1, display: 'inline-block', backgroundColor: 'lightblue' }}
                  >
                      <Typography variant="body2">{total} training locations</Typography>
                  </Card>
              </Box>
          )}
      </>
  );
};

const TveTrainingLocationListActions = () => (
  <TopToolbar>
    <CreateButton label="Add Tve Training Location" variant="contained" />
    <SelectColumnsButton variant="contained" />
  </TopToolbar>
);

const BulkActionButtons = () => <></>;

const TveTrainingLocationList = () => (
  <InfiniteList pagination={<CustomPagination />}
    title={<TveTitle pageName="Training Locations" />}
    bulkActionButtons={<BulkActionButtons />}
    sort={{ field: 'sort', order: 'ASC' }}
    perPage={50}
    actions={<TveTrainingLocationListActions />}
  >
    <DatagridConfigurable omit={['sort', 'totalClassesUsingThisLocation']} rowClick="edit">
      <TextField label="Training Location" source="location" />
      <TextField label="Location Code" source="locationCode" />
      <TextField label="State" source="state" />
      <TextField label="Country" source="country" />
      <TextField label="Display Name" source="displayName" />
      <TextField label="Default Time Zone" source="tveTimeZone.official" />
      <FunctionField
        label="Current Local Date & Time"
        render={(record) =>
          record.tveTimeZone?.official && <Clock timeZone={record.tveTimeZone?.official} />
        }
      />
      <TextField label="Sort Order" source="sort" />
      <TextField label="# Classes Using" source="totalClassesUsingThisLocation" />
    </DatagridConfigurable>
  </InfiniteList>
);
export default TveTrainingLocationList;
